import React, { useCallback } from 'react';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Button, {
  COLOR_VARIANTS as BUTTON_COLORS,
  SIZES_VARIANTS as BUTTON_SIZES,
} from 'components/atoms/Button/Component';
import Paragraphs, {
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
  SIZE_VARIANTS as PARAGRAPHS_SIZES,
} from 'components/atoms/Paragraphs/Component';

import useIsMounted from 'hooks/useIsMounted';

import useParagraphsAlign from '../../hooks/useParagraphsAlign';
import useCookieView from './hooks/useCookieView';

import { propTypes, defaultProps } from '../propTypes';

import styles from './Component.module.scss';

function CookieInfo({ disableFixed, illustration, paragraphs, action }) {
  const isMounted = useIsMounted();

  const { show, acceptHandler } = useCookieView(isMounted, disableFixed);

  const paragraphsAlign = useParagraphsAlign({
    isMounted,
    skipTablet: true,
    skipWideMobile: true,
  });

  const renderIllustration = useCallback(() => {
    if (!illustration) {
      return null;
    }

    return (
      <img
        className={styles.illustration}
        src={illustration}
        alt=""
        role="presentation"
        width={48}
        height={48}
      />
    );
  }, [illustration]);

  const renderAction = useCallback(() => {
    if (!action) {
      return null;
    }

    return (
      <Button
        className={styles.action}
        size={BUTTON_SIZES.SMALL}
        color={BUTTON_COLORS.BASE_DARK}
        onClick={acceptHandler}
      >
        {action}
      </Button>
    );
  }, [acceptHandler, action]);

  return (
    <CSSTransition
      in={show}
      timeout={{ enter: 500, exit: 400, appear: 0 }}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={cls(styles.ComponentRoot, disableFixed && styles.notFixed)}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {renderIllustration()}
            <Paragraphs
              className={styles.paragraphs}
              size={PARAGRAPHS_SIZES.SMALL}
              color={PARAGRAPHS_COLORS.DARK_200}
              align={paragraphsAlign}
            >
              {paragraphs}
            </Paragraphs>
            {renderAction()}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

CookieInfo.propTypes = propTypes;
CookieInfo.defaultProps = defaultProps;

export default CookieInfo;
