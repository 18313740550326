import PropTypes from 'prop-types';

export const propTypes = {
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  action: PropTypes.oneOfType([() => null, PropTypes.node.isRequired]),
  illustration: PropTypes.oneOfType([() => null, PropTypes.string.isRequired]),
};

export const defaultProps = {
  action: null,
  illustration: null,
};
