import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { Link } from 'react-router-dom';

import _noop from 'lodash/noop';

import { COLOR_VARIANTS, SIZES_VARIANTS, TYPE_VARIANTS, TARGET_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import styles from './Component.module.scss';

function Button({ children, href, target, onClick, className, color, type, size, disabled }) {
  const classNames = cls(styles.Button, styles[`${color}Button`], styles[`${size}Size`], className);

  if (href) {
    if (href.startsWith('/')) {
      return (
        <Link to={href} target={target} className={classNames} onClick={onClick}>
          {children}
        </Link>
      );
    }

    return (
      <a href={href} target={target} className={classNames} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <button className={classNames} type={type} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.oneOfType([PropTypes.string, () => null]),
  target: PropTypes.oneOf(Object.values(TARGET_VARIANTS)),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPE_VARIANTS)),
  ...propTypes,
};

Button.defaultProps = {
  href: null,
  target: TARGET_VARIANTS.SELF,
  onClick: _noop,
  type: TYPE_VARIANTS.BUTTON,
  ...defaultProps,
};

export { COLOR_VARIANTS, SIZES_VARIANTS, TYPE_VARIANTS, TARGET_VARIANTS };
export default Button;
