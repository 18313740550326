import { useCallback, useEffect, useRef, useState } from 'react';
import Cookie from 'js-cookie';
import { useLocation } from 'react-router-dom';

import usePageLang from 'hooks/usePageLang';
import usePreviousValue from 'hooks/usePreviousValue';

import { LANG_MAP } from 'constants/common';

const SCROLL_THRESHOLD = 60;
const EXPIRES = 365;
const COOKIE_LINK_LOCATOR_NAME = 'policy-link';
const COOKIE_POLICY_ACCEPTED_NAME = 'travelee-cookie-policy-has-accepted';

export default function useCookieView(isMounted, adminView) {
  const prevIsMounted = usePreviousValue(isMounted);

  const { pathname } = useLocation();
  const lang = usePageLang(pathname);

  const [shouldListen, setListeningTo] = useState(false);
  const [showCookie, changeCookieShowingTo] = useState(false);

  const prevPageYOffsetRef = useRef(0);
  const scrollDeltaRef = useRef(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isMounted && !prevIsMounted) {
      const cookieIsNotSet = Cookie.get(COOKIE_POLICY_ACCEPTED_NAME) !== 'true';
      if (cookieIsNotSet) {
        timeoutRef.current = setTimeout(() => {
          changeCookieShowingTo(true);
          if (!shouldListen && lang === LANG_MAP.RU) {
            setListeningTo(true);
          }
        }, 1000);
      }
      if (adminView) {
        changeCookieShowingTo(true);
      }
    }
  }, [isMounted, prevIsMounted, shouldListen, lang, adminView]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const acceptCookieHandler = useCallback(() => {
    setListeningTo(false);
    changeCookieShowingTo(false);
    if (!adminView) {
      Cookie.set(COOKIE_POLICY_ACCEPTED_NAME, true, { expires: EXPIRES });
    }
  }, [adminView]);

  const scrollHandler = useCallback(() => {
    const delta = window.pageYOffset - prevPageYOffsetRef.current;
    scrollDeltaRef.current += delta;

    if (Math.abs(scrollDeltaRef.current) > SCROLL_THRESHOLD && showCookie) {
      acceptCookieHandler();
    }

    prevPageYOffsetRef.current = window.pageYOffset;
  }, [showCookie, acceptCookieHandler]);

  const bodyClickHandler = useCallback(
    (event) => {
      if (!showCookie) {
        return;
      }

      if (event.target.getAttribute('data-locator') === COOKIE_LINK_LOCATOR_NAME) {
        return;
      }

      acceptCookieHandler();
    },
    [showCookie, acceptCookieHandler],
  );

  useEffect(() => {
    if (shouldListen) {
      window.addEventListener('scroll', scrollHandler);
      document.body.addEventListener('click', bodyClickHandler);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      document.body.removeEventListener('click', bodyClickHandler);
    };
  }, [shouldListen, scrollHandler, bodyClickHandler]);

  return { show: showCookie, acceptHandler: acceptCookieHandler };
}
